<script>
import LoginForm from '@/components/LoginForm.vue';

export default {
  name: 'LoginPage',
  components: {
    LoginForm,
  },
};
</script>

<template>
<div>
  <b-container>
    <b-row class='mt-5'>

      <!-- Begin LH Marketing Side -->
      <b-col lg=8 md=6 sm=12 order=2 order-md=1 >
        <b-card>
          <p class='lead'>
            Welcome to PMGateway Online
          </p>
          <p>
            Log in to view and download from your {{ $t('ranger_analysers') }} worldwide.
          </p>
          <b-img src="/img/marketing01.png" fluid></b-img>
          <p>
            Support:<br>
            <ul>
              <li>
                <b-link href="mailto:support@synergy-mi.com">
                  support@synergy-mi.com
                </b-link>
                (USA)
              </li>
              <li>
                <b-link href="mailto:support@outramresearch.co.uk">
                  support@outramresearch.co.uk
                </b-link>
                (UK and Rest of World)
              </li>
            </ul>
            Visit
            <b-link href="https://www.outramresearch.co.uk/contact-us/">
              https://www.outramresearch.co.uk/contact-us/
            </b-link>
            for more contact details.
          </p>
        </b-card>
      </b-col>

      <!-- Begin RH Login Side -->
      <b-col lg=4 md=6 sm=12 order=1 order-md=2 class='mb-2'>
        <b-card>
          <login-form></login-form>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
  </div>
</template>

<style lang='scss' scoped>
@import '../style/bs-theme.scss';

.header {
  background-color: $primary;
}

ul {
  padding-left: 1em;
}

</style>
