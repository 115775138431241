<script>
import formVal from '@/plugins/form-validator';

export default {
  name: 'LoginForm',
  data() {
    return {
      login_data: {
        email: localStorage.getItem('email') || '',
        password: '',
        rememberMe: !!localStorage.getItem('email'),
        status: null,
      },
      reset_data: {
        email: '',
        validation: null,
        status: 'WAITING',
      },
    };
  },
  methods: {
    /**
     * Perform a login with the credentials provided
     */
    login(evt) {
      evt.preventDefault(); // Suppress native form submission
      this.$store.dispatch('user/login', this.login_data)
        .then(() => {
          if (this.$route.params.from && this.$route.params.from !== '/') {
            this.$router.replace(this.$route.params.from);
          } else {
            this.$router.replace('/loggers');
          }
        })
        .catch(() => {
          this.login_data.status = 'Login Failed. Please try again';
        });
    },
    /**
     * Perform a password reset on the provided account
     */
    resetPassword(evt) {
      // Prevent the modal from closing immediately
      evt.preventDefault();
      if (this.reset_data.status === 'DONE') {
        return;
      }
      if (formVal.validate.single(this.reset_data.email, { presence: true, email: true })) {
        this.reset_data.validation = false;
      } else {
        this.reset_data.validation = true;
        this.$store.dispatch('user/forgotPassword', this.reset_data.email)
          .then(() => {
            this.reset_data.status = 'DONE';
          });
      }
    },
  },
};
</script>

<template>
  <div>
    <!-- Start Login Form -->

    <b-alert variant='warning' :show='!!this.$route.params.session_expired'>
      Your session expired, please log in again to continue
    </b-alert>

    <b-alert variant='warning' :show='!!this.$route.params.not_logged_in'>
      Please log in to continue
    </b-alert>

    <b-alert variant='danger' v-bind:show='!!this.login_data.status'>
      {{ this.login_data.status }}
    </b-alert>

    <b-form @submit="login">
      <b-form-group
        label='Email address'>
        <b-input type='email' v-model='login_data.email' />
      </b-form-group>
      <b-form-group label='Password'>
        <b-input type='password' v-model='login_data.password' />
      </b-form-group>
      <b-checkbox v-model='login_data.rememberMe'>
        Remember Me
      </b-checkbox>
      <b-button variant="primary" type='submit' class='mt-1 mb-2' block>Login</b-button>
      <b-link @click="$bvModal.show('reset_password')" >Forgot your password?</b-link>
    </b-form>
    <!-- End Login Form -->

    <!-- Password Reset Modal -->
    <b-modal id='reset_password'
            title='Password Reset'
            @ok='resetPassword'
            v-bind:busy="reset_data.status === 'DONE'" >
      <p>
        Enter your email address below.
        If you have an account with us, you'll receive a password reset link by email.
      </p>
      <b-alert variant='success' v-bind:show="reset_data.status === 'DONE'">
        Please check your email for a reset token.
      </b-alert>
      <b-form-group label='Email'>
        <b-input type='email'
                v-model='reset_data.email'
                v-bind:state='reset_data.validation'
                v-on:keyup.enter='resetPassword'
                v-bind:disabled="reset_data.status === 'DONE'" />
        <b-form-invalid-feedback>Please enter a valid email address</b-form-invalid-feedback>
      </b-form-group>
    </b-modal>
  </div>
</template>
